@import '../_customize_bootstrap';

@media (min-width: 0px) {
  .app-header {
    height: 4em;
    position: fixed;
    width: 100%;
    z-index: 10;
    font-family: 'Cricket';
    box-shadow: none;
    transition: all 0.3s ease-in;

    & .container {

      position: relative;

      .quick-address,
      .quick-contact {
        position: absolute;
        bottom: -2em;
        left: 0.7em;
        background: $primary;
        padding: 0.15em 0.75em 0.35em;
        border-bottom-left-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        z-index: 9;
        font-size: 1.5em;
        transition: all 0.2s ease-in-out;
        height: 2em;
        opacity: 1;

        a {
          color: $light;

          &:hover {
            text-decoration: none;
          }
        }

        span {
          text-transform: uppercase;
          font-size: 0.7em;
          margin-left: 0.5em;
        }

        &:hover {
          background: $warning;
          cursor: pointer;
          text-decoration: none;
        }
      }

      .quick-contact {
        left: 6em
      }
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      content: '';
      height: 3px;
      background: #2baac3;
      background: linear-gradient(90deg, $primary 0%, $light 50%, $warning 100%);
      z-index: 10;
    }

    &.active {
      box-shadow: 0 0 0.8em rgba(0, 0, 0, 0.4);

      .quick-address,
      .quick-contact  {
        opacity: 0;
      }
    }

    .nav-link {
      transition: all 0.3s ease;
    }

    .navbar-light .navbar-toggler {
      border-radius: 0.2em;
      background-color: transparent;
      border: none;
      padding: 0;

      .navbar-toggler-icon {
        background-image: url('../../assets/images/burger-menu.svg');
        background-color: $light;
        width: 2em;
        height: 2em;
      }
    }

    #responsive-navbar-nav {
      background: $light;
      border: 1px solid $color-theme-white-brgd-text;
      border-bottom-left-radius: 1em;
      border-bottom-right-radius: 1em;
      padding: 3.5em 1em 1.5em;

      .main-nav {
        text-align: center;
      }
    }

    nav,
    .container {
      height: 100%;
    }

    .navbar-light .navbar-nav .dropdown-menu.show {
      max-width: 65%;
      margin: 0 auto !important;
      text-align: center;
    }

    .navbar-light .navbar-nav .dropdown-item {
      font-size: 1.45em;
      padding: 0.6em 0;
    }

    .navbar-light .navbar-nav .nav-link {
      position: relative;
      margin-left: 0.25em;
      font-size: 1.45em;
      padding: 0.6em 0;

      &:not(:first-child):after {
        position: absolute;
        content: '';
        display: none;
        background-color: $warning;
        height: 1.2em;
        width: 1px;
        top: 50%;
        transform: translateY(-50%);
        left: -0.2em;
      }
    }

    nav {
      background-color: #e9ecef;
      background-image: url('../../assets/images/floating-cogs.svg');
      background-size: 300px 300px;
    }

    .app-logo {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 80%;
      }
    }

    .app-slogan {
      font-size: 1.2em;
      // font-family: 'Montserrat';
      color: $color-font-black;
      font-weight: bold;
      display: none;
      align-items: center;
      margin-left: 0.4em;
    }

    .loader-wrapper {
      width: 8em;
    }

    .gears-loader-small {
      height: 2em;
      width: 2em;
      padding-top: 0.5em;
    }
  }
}

// SM - Medium devices (tablets, 576px and up)
@media (min-width: 576px) {
  .app-header {
    .app-slogan {
      display: flex;
    }
  }
}

// MD - Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}

// XL
@media (min-width: 1200px) {
  .app-header {
    height: 4em;

    #responsive-navbar-nav {
      margin-top: 0;
      border: none;
      padding: 0;
      background-color: transparent;

      .main-nav {
        text-align: center;
      }
    }

    nav,
    .container {
      height: 100%;
    }

    .navbar-light .navbar-nav .dropdown-menu.show {
      text-align: left;
    }

    .navbar-light .navbar-nav .nav-link,
    .navbar-light .navbar-nav .dropdown-item {
      font-size: 1em;
      padding: 0.5rem;

      &:not(:first-child):after {
        display: block;
      }
    }

    .navbar-light .navbar-nav .dropdown-item {
      padding-left: 1.4em;
    }

    .login-admin-ico {
      width: 1.2em;
      height: 1.2em;
      margin-top: -0.2em;
    }


    .login-user-ico {
      width: 1.3em;
      height: 1.3em;
      margin-top: -0.2em;
    }

    .app-slogan {
      font-size: 1em;
      color: $color-font-black;
      font-weight: bold;
      align-items: center;
      margin-left: 0.4em;
    }
  }
}