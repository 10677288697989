@import '../_customize_bootstrap';

.goTop {
  // display: none;
  pointer-events: none;
  opacity: 0;
  position: fixed;
  z-index: 10;
  bottom: 0.75em;
  right: 1em;
  font-size: 2.25em;
  right: calc(50% - 17em);
  // rotate: -136deg;
  transition: all 0.5s ease-in-out;
  color: $primary;
  padding: 0.7em;
  border-radius: 50%;
  height: 1.25em;
  width: 1.25em;

  &.active {
    // display: block;
    pointer-events: all;
    opacity: 0.6;

    &:hover {
      color: $warning;
    }

    .go-top-icon {
      filter: drop-shadow(1px 1px 0px rgba(233, 236, 239, 0.3)) drop-shadow(-1px 1px 0px rgba(233, 236, 239, 0.3)) drop-shadow(1px -1px 0px rgba(233, 236, 239, 0.3)) drop-shadow(-1px -1px 0px rgba(233, 236, 239, 0.3));
    }
  }
}