@import '../_customize_bootstrap';

.site-footer {
  background: rgb(23, 114, 69);
  background: linear-gradient(138deg, rgba(23,114,69,1) 57%, rgba(244,119,44,1) 100%);
  overflow: hidden;

  .footer-logo-large {
    background: rgba(233, 236, 239, 0.5);
    position: relative;
    height: 8em;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 35em;
    }
  }

  .container {
    background: none;
    color: #e9ecef;

    a,
    h5 {
      color: #e9ecef;
    }
  }

  header {
    margin-bottom: 2em;
  }

  ul {
    list-style: none outside;
    padding: 0;
    margin: 1em 0 0;

    li {
      margin-bottom: 1em;
      padding-left: 0;
    }
  }

  .widget-sitemap {
    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        flex: 0 0 48%;
      }
    }
  }

  .widget-contacts {
    ul li {
      margin-left: -0.8em;
    }
  }

  .footer-end {

    margin-top: auto;

    .container {
      padding: 0.35em 0;

      &::before {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background: rgba(233, 236, 239, 0.5);
        opacity: 0.5;
        margin-bottom: 0.4em;
      }
    }

    .footer-logo {
      height: 1.4em;
      margin: -0.15em 0.2em 0 0.4em;
    }
  }
}