@keyframes zoomIn {
	from {
		scale: 1.1;
	}
	to {
		width: 1;
	}
}



@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeInSoft {
	from {
		opacity: 0.5;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes jump {
	0% {
		margin-top: -0.4em;
	}

	50% {
		margin-top: 0.4em;
	}

	100% {
		margin-top: -0.4em;
	}
}

@keyframes radiate {

	0% {
		box-shadow: 0 0 0 0 rgba(43, 170, 195, 0.4);
	}
	70% {

		box-shadow: 0 0 0 0.5em rgba(43, 170, 195, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(43, 170, 195, 0);
	}
}

@keyframes slides {
	0% {
		margin-left: -100%;
	}

	100% {
		margin-left: 0;
	}
}