@import '../customize_bootstrap';

.toast-alert {
  position: absolute;
  top: 6em;
  right: 3em;
  border-radius: $border-radius-main;
  background-color: none;
  z-index: 100;


  &.error {
    color: #740c03;

    .toast-header {
      padding: 0 1em 0 1em;
      color: $pure-white;
      background: #EE363E;
      border-top-left-radius: $border-radius-main;
      border: 1px solid #EE363E;
      font-size: 1.4em;

      button {
        color: $pure-white;
        opacity: 1;
        text-shadow: none;
        right: 0.4em;
        position: absolute;
      }
    }

    .toast-body {
      padding: 1em 2em 1em 1.4em;
      color: $pure-white;
      background: #EE363E;
      border: 1px solid #EE363E;
      border-bottom-left-radius: $border-radius-main;
      border-bottom-right-radius: $border-radius-main;

      .toast-error-msg {
        position: relative;
        padding-left: 2em;

        .warning-icon {
          color: $pure-white;
          font-size: 1.5em;
          position: absolute;
          top: -0.05em;
          left: -0.05em;
        }
      }
    }
  }
}