.gears-loader {
  position: relative;
  width: 5em;
  height: 5em;
  display: inline-block;

  .gear-big {
    font-size: 3em;
    animation: spin 7.5s linear infinite;
    position: absolute;
    top: 0.3em;
    left: 0;
  }

  .gear-mid {
    font-size: 2.2em;
    animation: spinReverse 7s linear infinite;
    position: absolute;
    top: 1.1em;
    left: 1.2em;
  }

  .gear-small {
    font-size: 1.5em;
    animation: spinReverse 6.25s linear infinite;
    position: absolute;
    top: 0.3em;
    left: 1.75em;
  }
}

.gears-loader-small {
  position: relative;
  width: 3em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;

  .gear-big {
    font-size: 3em;
    animation: spin 7.5s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes spinReverse {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}
