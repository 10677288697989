@import './_customize_bootstrap';
@import 'node_modules/bootstrap/scss/bootstrap';
@import './_mixins';
@import './_typography';
@import './_animations';

body {
  background-color: #e9ecef;
  background-image: url('../assets/images/floating-cogs.svg');
  background-attachment: fixed;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.row {
  margin: 0;
}

.coming-soon {
  margin: 2em;
  width: 40%;
}

.btn {
  font-family: "Cricket";
  font-size: 1.1em;
  text-transform: uppercase;
}

.app-main {
  padding: 4em 0 0;
  min-height: 88vh;
}

.page-container {
  padding-top: 2em;
}

.container {
  padding: 1em;
  background: rgba(233, 236, 239, 0.5);
  max-width: 70em;
  padding-bottom: 4em
}

.page-item {
  padding: 0;
}

.page-load {
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15em;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(51, 51, 51, 0.5);
  box-shadow: none;
  outline: 0 none;
}

.form-control:invalid {
  outline: none;
}

table.table>thead {
  font-weight: bold;
  background-color: $color-main-bgrd;

  th {
    vertical-align: middle;
  }
}

.campic-quote {
  font-weight: bold;
  color: rgba(51, 51, 51, 0.5);
  white-space: nowrap;
}

.bullet {

  position: relative;

  &:before {
    content: '•';
    position: absolute;
    margin-left: -1em;
  }
}

// scrollbar

html,
textarea {

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: $warning;
    color: #aadacf;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0);
  }

  // for Mozilla
  scrollbar-width: thin;
  scrollbar-color: $warning rgba(255, 255, 255, 0);
}

.comments-list {
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }
}

.login-ico {
  width: 1.2em;
  height: 1.2em;
  margin-top: -0.2em;
}

.add-ico {
  font-size: 1.2em;
  margin-top: -2px;
}

.send-ico {
  font-size: 1.4em;
  margin-top: -1px;
  margin-left: 4px;
}

.send-inquiry {
  font-size: 1.1em;
  margin-top: -2px;
  margin-left: 4px;
}

.close-ico {
  font-size: 1.6em;
}


// Small devices (phones)
@media (min-width: 0px) {
  html {
    font-size: $font-size-phone;
  }

  .edit-form {
    position: relative;
    margin: 1em auto;
    z-index: 1;
    background: $light;
    border: 1px solid rgba(51, 51, 51, 0.5);
    border-radius: $border-radius-main;
    max-width: 95%;
    padding: 0 1em;
    height: 90%;
    overflow-y: auto;
    flex: 1 1 auto;
  }
}

@media (min-width: 576px) {
  .edit-form {
    max-width: 80%;
  }
}

// MD - Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  html {
    font-size: $font-size-tablet;
  }

  .edit-form {
    max-width: 45em;
    padding: 0 4em;

    &.user-auth-form {
      max-width: 40em;
    }
  }
}

// LG - Large devices (desktops, 992px and up)
// @media (min-width: 992px) {}

// XL - Extra Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  html {
    font-size: $font-size-desktop;
  }

  .edit-form {
    padding: 1em 4em;
  }
}