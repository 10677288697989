@include add-font('Montserrat', normal, normal, '../assets/fonts/Montserrat/Montserrat-Regular.ttf');
@include add-font('Montserrat', normal, italic, '../assets/fonts/Montserrat/Montserrat-Italic.ttf');
@include add-font('Montserrat', bold, normal, '../assets/fonts/Montserrat/Montserrat-Bold.ttf');
@include add-font('Montserrat', bold, italic, '../assets/fonts/Montserrat/Montserrat-BoldItalic.ttf');

@include add-font('Cricket', bold, normal, '../assets/fonts/Cricket/cricket-bold.ttf');

*, *:before, *:after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font: #{$font-size-phone}/1.5 'Montserrat', sans-serif;
	color: $color-font-black;
}

body, form, input, option, select, textarea, table, th, td {
	font: inherit;
}

$headings: h1, h2, h3, h4, h5, h6;
$heading-size: 3.2;
$step: 1;

@each $heading in $headings {

	#{$heading} {
		font-size: #{$heading-size}rem;
	}

	$heading-size: $heading-size - $step;

	@if ($step > 0.4) {
		$step: $step - 0.8
	}
}

#{$headings} {
	font-family: 'Cricket';
	line-height: 1.1em;
	margin: 0.3em 0 0.5em 0;
	font-weight: bold;
	color: $color-campic-green;
}

p, ul, ol {
	padding-bottom: 1.5em;
}

ul, ol {
	padding-left: 2em;
}

li {
	padding-left: 0.5em;
}

ul ul, ul ol, ol ul, ol ol {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

a {
	text-decoration: none;
	color: $color-font-black;
}

blockquote {
	padding: 1rem;
	font-style: italic;
	opacity: 0.5;

	.quote:before,
	.quote:after {
		content: '"';
		padding: 0 0.2em;
	}

	.author {
		text-align: right;

		&:before {
			display: inline-block;
			content: "‒";
			padding-right: 0.5em;
		}
	}
}