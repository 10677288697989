// Colors
$primary: #177245;
$warning: #f4772c;
$danger: #e01705;
$secondary: #8e9091;
$light: #e9ecef;
$info: #0062cc;

// $dark: tomato;

// Options
$enable-responsive-font-sizes: true;

// Body 
$body-color: #333333; // Text color
$form-color: #8e9091; // Text color

//Card
$card-bgrd-color: #f4f6f7;

// Typography
$font-family-base: "Poppins";
$headings-font-weight: 700;
$headings-margin-bottom: 0;
$paragraph-margin-bottom: 0;

// Spacers
$spacer:            1rem;

$spacers: (
  0: 0,
  1: ($spacer * .125),  // 2px
  2: ($spacer * .25),   // 4px
  3: ($spacer * .5),    // 8px
  4: $spacer,           // 16px
  5: ($spacer * 1.5),   // 24px
  6: ($spacer * 2),     // 32px
  7: ($spacer * 2.5),   // 40px
  8: ($spacer * 3),     // 48px
  9: ($spacer * 3.5),   // 56px
  10: ($spacer * 4),    // 64px
  11: ($spacer * 4.5),  // 72px
  12: ($spacer * 5),    // 80px
  13: ($spacer * 5.5),  // 88px
  14: ($spacer * 6),    // 96px
  15: ($spacer * 7),    // 112px
);

// Buttons
$btn-padding-y: 14px;
$btn-padding-x: 28px;
$btn-border-radius: 50px;




// Private vars ----------------

//colors
$color-campic-green: #177245;
$color-campic-green-light: #4dcb91;

$color-blue: #0062cc;

$color-theme-white-brgd-text: #747F83;
$color-main-bgrd: #d1d1d3;

$color-theme-grey: #A3ABBD;

$pure-white: #FFFFFF;
$color-site-bgrd: #DFE0E4;
$color-font-black: #333333;
$color-font-light: #fbf8f2;
$color-soft-black: #231F20;

//font size
$font-size-large-desktop: 15px;
$font-size-desktop: 14px;

$font-size-tablet: 13px;

$font-size-phone: 12px;

//radius

$border-radius-main: 1rem;
$border-radius-mid: 0.4rem;
$border-radius-small: 0.25rem;
