@import '../_customize_bootstrap';

.policy-alert {
  position: fixed;
  background-color: $light;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 7em;
  z-index: 100;
  box-shadow: -2px -20px 80px 13px rgb(51, 51, 51, 0.7);
  border-top: 3px solid $warning;

  .cookie-policy-btn {
    color: $info;
    font-family: 'Cricket';
    // text-decoration: underline;
    font-size: 1.1em;
    margin-left: 0.25em;
    display: inline-block;
  }
}